import { message } from "antd";
import axios from "axios";
const UserBooking = ({
  vendor_id,
  user_id,
  Booking_type,
  first_name,
  last_name,
  cartItems,
  booking_date,
  booking_time,
  service_durantion,
  trevel_to_me,
  total_bill,
  /* paymentType */
  payerEmail,
  trnsID,
  total_deposit,
  remaining_balance,
  paypalResponseData,
}) => {
  const data = {
    vendor_id,
    user_id,
    cartItems,
    first_name,
    last_name,
    booking_date,
    booking_time,
    service_durantion,
    trevel_to_me,
    total_bill,
    total_deposit,
    payer_email: payerEmail,
    transactionId: trnsID,
    remaining_balance,
    paypalResponseData,
  };
  const URL =
    Booking_type === "InstantBooking"
      ? `${process.env.REACT_APP_BASE_URL}/api/user/instant_booking`
      : `${process.env.REACT_APP_BASE_URL}/api/user/normal_booking`;
  axios
    .post(URL, { ...data })
    .then((res) => {
      console.log(`response=${URL}`, res);
      if (res?.data?.success) {
        window.location = `/userbookings/${user_id}`;
        message.success(res?.data?.message);
      } else {
        console.log(res?.data?.message);
        message.error("Some Error Occurred");
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default UserBooking;
