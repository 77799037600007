export const timeAvail = []; // Keep it a constant array reference

export function addTime(newTimes) {
  console.log(newTimes);
  if (Array.isArray(newTimes)) {
    // Add new items without reassigning the array reference
    timeAvail.push(...newTimes);
  } else {
    console.warn("addTime expects an array of times");
  }
}

export const getUnique = (arr, key = "date") => {
  const uniqueIds = [];

  return arr.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[key]);

    if (!isDuplicate) {
      uniqueIds.push(element[key]);

      return true;
    }

    return false;
  });
};
